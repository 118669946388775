import {FormEventHandler, useContext, useEffect, useState} from "react";
import {GridOptions, ValueFormatterParams} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {Link} from "react-router-dom";
import {AuthContext} from "../../../contexts/authContext";
import IndustryFloatingFilter from "../../../floatingFilters/IndustryFloatingFilter";
import JobFunctionFloatingFilter from "../../../floatingFilters/JobFunctionFloatingFilter";
import StatusFloatingFilter from "../../../floatingFilters/StatusFloatingFilter";
import moment from 'moment';

export default function Index() {
    // https://stackoverflow.com/questions/58174399/ag-grid-create-a-filter-drop-down-on-column-values

    const [gridApi, setGridApi] = useState<any>(null);
    const [industries, setIndustries] = useState([]);
    const [jobFunctions, setJobFunctions] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [me, setMe] = useState<null | any>(null);
    const [exportIndex, setExportIndex] = useState<null | any>(null);

    const columnDefs = [
        {
            headerName: '',
            filter: false,
            sortable: false,
            resizable: false,
            cellRenderer: (props: any) => {
                if (!props.data) {
                    return;
                }
                return (
                    <Link to={`/dashboard/candidates/${props.data.id}/edit`} style={{backgroundColor: '#0d6efd', color: '#fff', display: 'inline-block', textAlign: 'center', textDecoration: 'none', borderRadius: '0.375rem', lineHeight: 1.5, padding: '0.375rem 0.75rem', width: '100%'}}>
                        Edit
                    </Link>
                );
            },
            width: 100,
        },
        {
            field: 'name',
            headerName: 'Name',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
            },
            headerCheckboxSelection: true,
            checkboxSelection: true,
        },
        {
            field: 'email',
            headerName: 'Email',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
            },
            cellRenderer: (props: any) => {
                if (!props.data) {
                    return null;
                }
                if (!props.data.email) {
                    return null;
                }
                return (
                    <a href={"mailto:" + props.data.email} target={"_blank"}>{props.data.email}</a>
                );
            },
        },
        {
            field: 'phone',
            headerName: 'Phone',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
            },
            cellRenderer: (props: any) => {
                if (!props.data) {
                    return null;
                }
                if (!props.data.phone) {
                    return null;
                }
                if (props.data.phone.length === 8) {
                    return (
                        <a href={"https://wa.me/852" + props.data.phone} target={"_blank"}>{props.data.phone}</a>
                    );
                }

                return (
                    <a href={"https://wa.me/" + props.data.phone} target={"_blank"}>{props.data.phone}</a>
                );
            },
        },
        {
            field: 'expected_salary',
            headerName: 'Expected Annual Salary',
            filter: 'agNumberColumnFilter',
            filterParams: {
                filterOptions: ['equals'],
            },
        },
        {
            field: 'received_at',
            headerName: 'Received At',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['equals'],
            },
            valueFormatter: (params: any) => {
                if (params.value) {
                    return moment(params.value).format('YYYY-MM-DD HH:mm');
                }

                return null;
            },
        },
        {
            field: 'application_title',
            headerName: 'Title',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
            },
        },
        {
            field: 'email_subject',
            headerName: 'Subject',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
            },
        },
        {
            field: 'years_of_experience',
            headerName: 'Years Of Experience',
            filter: 'agNumberColumnFilter',
            filterParams: {
                filterOptions: ['equals'],
            },
        },
        {
            field: 'job_function_id',
            headerName: 'Job Function',
            sortable: false,
            suppressMenu: true,
            floatingFilterComponent: JobFunctionFloatingFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
                jobFunctions: jobFunctions,
            },
            valueFormatter: (params: ValueFormatterParams) => {
                const mapping = new Map();
                for (let jobFunction of (jobFunctions as any)) {
                    mapping.set(jobFunction?.id, jobFunction?.name);
                }

                if (!mapping.has(params.value)) {
                    return '';
                }

                return mapping.get(params.value);
            },
        },
        {
            field: 'industry_id',
            headerName: 'Industry',
            sortable: false,
            suppressMenu: true,
            floatingFilterComponent: IndustryFloatingFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
                industries: industries,
            },
            valueFormatter: (params: ValueFormatterParams) => {
                const mapping = new Map();
                for (let industry of (industries as any)) {
                    mapping.set(industry?.id, industry?.name);
                }

                if (!mapping.has(params.value)) {
                    return '';
                }

                return mapping.get(params.value);
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            suppressMenu: true,
            floatingFilterComponent: StatusFloatingFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
        },
        {
            field: 'repeat_email_count',
            headerName: 'Repeat Email',
            sortable: false,
            filter: false,
            cellRenderer: (props: any) => {
                return (
                    <button onClick={() => {handleFilterRepeatEmail(props)}} style={{border: '1px solid #0d6efd', background: 'transparent', color: '#0d6efd', borderRadius: '.375rem', cursor: 'pointer'}}>
                        {props.value}
                    </button>
                )
            },
        },
        {
            field: 'repeat_phone_count',
            headerName: 'Repeat Phone',
            sortable: false,
            filter: false,
            cellRenderer: (props: any) => {
                return (
                    <button onClick={() => {handleFilterRepeatPhone(props)}} style={{border: '1px solid #0d6efd', background: 'transparent', color: '#0d6efd', borderRadius: '.375rem', cursor: 'pointer'}}>
                        {props.value}
                    </button>
                )
            },
        },
        {
            field: 'attachments',
            headerName: 'Attachment',
            sortable: false,
            filter: false,
            cellRenderer: (props: any) => {
                if (!Array.isArray(props.value)) {
                    return null;
                }

                if (props.value.length === 0) {
                    return null;
                }

                return (
                    <Link to={`/dashboard/candidates/${props.data.id}/edit`} style={{backgroundColor: '#0d6efd', color: '#fff', display: 'inline-block', textAlign: 'center', textDecoration: 'none', borderRadius: '0.375rem', lineHeight: 1.5, padding: '0.375rem 0.75rem', width: '100%'}}>
                        <i className="bi bi-files"></i>
                    </Link>
                );
            },
        },
    ];

    const gridOptions: GridOptions = {
        defaultColDef: {
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            lockVisible: true,
            filterParams: {
                maxNumConditions: 1,
            },
        },
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        loadingOverlayComponent: () => {
            return (
                <div className="ag-custom-loading-cell" style={{paddingLeft: '10px', lineHeight: '25px'}}>
                    <span>Please add mailbox first.</span>
                </div>
            )
        },
        noRowsOverlayComponent: () => {
            return (
                <div className="ag-custom-loading-cell" style={{paddingLeft: '10px', lineHeight: '25px'}}>
                    <span>No rows found.</span>
                </div>
            )
        },
    };

    const user = useContext(AuthContext);

    const handleEffect = async () => {
        if (!gridApi) {
            return;
        }
        if (!user) {
            return;
        }

        const dataSource = {
            getRows: async (params: any) => {
                const filterModel = params.filterModel;
                const sortModel = params.sortModel;
                const page = params.endRow / 20;

                const response = await fetch(process.env.REACT_APP_API_URL + `/api/candidates`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${await user?.getIdToken()}`,
                        "Accept": 'application/json',
                        "Content-Type": 'application/json',
                    },
                    body: JSON.stringify({
                        page,
                        filterModel,
                        sortModel,
                    }),
                });
                const json = await response.json();

                if (json.data === undefined) {
                    gridApi.showLoadingOverlay();
                    params.failCallback();
                    return;
                }

                if (json.data.length === 0) {
                    gridApi.showNoRowsOverlay();
                    params.successCallback(json.data, json.meta.total);
                    return;
                }

                gridApi.hideOverlay();
                params.successCallback(json.data, json.meta.total);
            },
        };

        gridApi.setDatasource(dataSource);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/industries', {
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Accept": 'application/json',
                },
            });
            const json = await response.json();
            setIndustries(json?.data);
        } catch (e) {
            console.log(e)
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/job-functions', {
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Accept": 'application/json',
                },
            });
            const json = await response.json();
            setJobFunctions(json?.data);
        } catch (e) {
            console.log(e)
        }

        const response = await fetch(process.env.REACT_APP_API_URL + '/api/auth/me', {
            headers: {
                "Authorization": `Bearer ${await user?.getIdToken()}`,
                "Accept": 'application/json',
            },
        });

        setMe({
            response: response,
            json: await response.json(),
        });
    };

    const handleGridState = async () => {
        if (!gridApi) {
            return;
        }

        const savedState = window.localStorage.getItem("ag-grid-state");
        if (savedState === null || savedState === '') {
            return;
        }

        gridApi.columnModel.applyColumnState(JSON.parse(savedState));

        window.setInterval(() => {
            if (!gridApi) {
                return;
            }
            
            const savedState = gridApi.columnModel.getColumnState();
            window.localStorage.setItem("ag-grid-state", JSON.stringify(savedState));
        }, 5000);
    };

    const handleExportIndex = async () => {
        if (!user) {
            return;
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/candidates/exports', {
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Accept": 'application/json',
                },
            });

            setExportIndex({
                response: response,
                json: await response.json(),
            });
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        handleEffect().then();
        handleExportIndex().then();
        handleGridState().then();
    }, [gridApi, user]);

    const handleFilterRepeatEmail = async (props: any) => {
        const email = props.data.email;

        await props.api.setFilterModel({
            email: {
                filterType: 'text',
                type: 'contains',
                filter: email,
            }
        });
    };

    const handleFilterRepeatPhone = async (props: any) => {
        const phone = props.data.phone;

        await props.api.setFilterModel({
            phone: {
                filterType: 'text',
                type: 'contains',
                filter: phone,
            }
        });
    };

    const handleExport: FormEventHandler<HTMLButtonElement> = async (event) => {
        event.preventDefault();

        if (me === null) {
            return;
        }

        if (me?.json?.data?.plan === null || me?.json?.data?.plan === 'before-trial' || me?.json?.data?.plan === 'trial') {
            alert("Please upgrade to basic plan to export your candidates.");
            return;
        }

        const response = await fetch(process.env.REACT_APP_API_URL + `/api/candidates/startExport`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${await user?.getIdToken()}`,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        });
        const json = await response.json();
        alert(json.message);
    };

    const handleDownloadExport: FormEventHandler<HTMLButtonElement> = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/api/candidates/downloadExport`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    handle: exportIndex.json.data,
                }),
            });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const fileLink = document.createElement('a');
            fileLink.href = url;
            fileLink.download = 'output.xlsx';
            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();
        } catch (e) {
            console.log(e);
        }
    };

    const handleRowSelected = (event: any) => {
        setSelectedRows(event.api.getSelectedRows());
    };

    const handleStatusUpdate = async (event: any, status: string) => {
        event.preventDefault();

        if (!window.confirm("Do you really want to update status for these candidates?")) {
            return;
        }

        try {
            await fetch(process.env.REACT_APP_API_URL + `/api/candidates:update_status`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                },
                body: JSON.stringify({
                    ids: selectedRows.map((selectedRow: any) => (selectedRow?.id)),
                    status,
                }),
            });

            window.location.reload();
        } catch (e) {
            console.log(e);
        }
    };

    const handleDelete = async (event: any) => {
        event.preventDefault();

        if (!window.confirm("Do you really want to delete these candidates?")) {
            return;
        }

        try {
            await fetch(process.env.REACT_APP_API_URL + `/api/candidates:destroy`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                },
                body: JSON.stringify({
                    ids: selectedRows.map((selectedRow: any) => (selectedRow?.id))
                }),
            });

            window.location.reload();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <div className={"p-2 d-flex flex-column"} style={{height: '100%'}}>
                <div className={"d-flex align-items-center"}>
                    <span className={"h1 mr-auto"}>Candidates</span>

                    <div className={"d-flex align-items-center gap-3"}>
                        {
                            selectedRows.length !== 0 &&
                            <>
                                <button className={"btn btn-outline-primary"} onClick={(event) => {handleStatusUpdate(event, 'pending')}}>Pending
                                    ({selectedRows.length})
                                </button>
                                <button className={"btn btn-outline-primary"} onClick={(event) => {handleStatusUpdate(event, 'shortlisted')}}>Shortlisted
                                    ({selectedRows.length})
                                </button>
                                <button className={"btn btn-outline-primary"} onClick={(event) => {handleStatusUpdate(event, 'rejected')}}>Rejected
                                    ({selectedRows.length})
                                </button>
                                <button className={"btn btn-danger"} onClick={handleDelete}>Delete
                                    ({selectedRows.length})
                                </button>
                            </>
                        }
                        <button className={"btn btn-outline-primary"} onClick={handleExport}>Export</button>
                        {
                            exportIndex?.json?.data
                            &&
                            <button className={"btn btn-outline-primary"} onClick={handleDownloadExport}>Download Export</button>
                        }
                        <Link to={"/dashboard/candidates/create"} className={"btn btn-primary"} target="_blank">Create</Link>
                    </div>
                </div>

                <div className="ag-theme-alpine" style={{flexGrow: 1, width: '100%'}}>
                    <AgGridReact
                        onGridReady={(params) => {setGridApi(params.api)}}
                        columnDefs={columnDefs as any}
                        pagination={true}
                        paginationPageSize={20}
                        cacheBlockSize={20}
                        rowModelType={'infinite'}
                        gridOptions={gridOptions}
                        onRowSelected={handleRowSelected}
                    >
                    </AgGridReact>
                </div>
            </div>
        </>
    );
}
