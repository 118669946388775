import {useContext, useEffect, useState, MouseEvent} from "react";
import {Link} from "react-router-dom";
import {AuthContext} from "../../../contexts/authContext";

export default function Index() {
    const user = useContext(AuthContext);
    const [mailboxes, setMailboxes] = useState<null | []>(null);

    const handleEffect = async () => {
        if (!user) {
            return;
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/mailboxes', {
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Accept": 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error("Cannot fetch mailboxes");
            }
            const json = await response.json();
            setMailboxes(json.data);
        } catch (e) {
            console.log(e)
        }
    };

    const handleDelete = async (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, id: string) => {
        event.preventDefault();

        if (!window.confirm("Do you really want to delete?")) {
            return;
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/api/mailboxes/${id}`, {
                method: 'DELETE',
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Accept": 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error("Cannot delete mailboxes");
            }
        } catch (e) {
            console.log(e)
        }

        handleEffect();
    };

    useEffect(() => {
        handleEffect().then();
    }, [user]);

    if (mailboxes === null) {
        return (
            <div className={"container p-2"}>
                <div className={"h1"}>Mailboxes</div>
            </div>
        );
    }

    return (
        <>
            <div className={"container p-2"}>
                <div className={"h1"}>Mailboxes</div>

                {
                    mailboxes.length === 0
                    ?
                        <div className={"d-flex"}>
                            <div className={"col-4"}>
                                <Link to={"/dashboard/mailboxes/create"} className={"rounded d-flex align-items-center justify-content-center w-100 text-decoration-none text-reset"} style={{height: 300, border: '2px dotted'}}>
                                    Add
                                </Link>
                            </div>
                        </div>
                    :
                        mailboxes.map((mailbox: any, index) => (
                            <div key={index} className={"d-flex"}>
                                <div className={"col-4"}>
                                    <div className={"card"}>
                                        <div className="card-body">
                                            <h5 className="card-title">{mailbox.host}</h5>
                                            <p className="card-text">{mailbox.inbox}</p>
                                            <p className="card-text">{mailbox.port}</p>

                                            <div className={"d-flex gap-3"}>
                                                <button role={"button"} className={"btn btn-danger"} onClick={(event) => {handleDelete(event, mailbox.id)}}>Delete</button>
                                                <Link to={`/dashboard/mailboxes/${mailbox.id}/edit`} className={"btn btn-primary"}>Edit</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                }
            </div>
        </>
    );
}
