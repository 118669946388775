import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyD0VGlxpJO2qkC0o8CK6JwGpJK3kP6Hmp4",
    authDomain: "easycandidate-e8e50.firebaseapp.com",
    projectId: "easycandidate-e8e50",
    storageBucket: "easycandidate-e8e50.appspot.com",
    messagingSenderId: "1024748366266",
    appId: "1:1024748366266:web:7254107e914fa057c91583"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {app, auth};