import {FormEventHandler, useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../contexts/authContext";
import { updatePassword } from "firebase/auth";

export default function Me() {
    const user = useContext(AuthContext);
    const [auth, setAuth] = useState({
        'plan': '',
    });

    const handleEffect = async () => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/auth/me', {
            headers: {
                "Authorization": `Bearer ${await user?.getIdToken()}`,
                "Accept": 'application/json',
            },
        });

        if (!response.ok) {
            return;
        }

        const json = await response.json();
        setAuth(prevState => (
            {
                plan: json.data.plan,
            }
        ));
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        if (user === null) {
            return;
        }

        const formData = new FormData(event.currentTarget);

        await updatePassword(user, formData.get("password") as string);

        event.currentTarget.reset();

        alert("Your password is updates.");
    };

    useEffect(() => {
        handleEffect().then();
    }, [user]);

    return (
        <>
            <div className={"container p-2"}>
                <div className={"h1"}>Profile</div>
                <div>{ user?.email }</div>

                <hr />

                {
                    (() => {
                        if (auth.plan === 'free-trial') {
                            return (
                                <div>
                                    <div>Free Trial</div>
                                    <div>Oct 14 - Oct 15</div>
                                    <div>
                                        <a href={"https://wa.me/1XXXXXXXXXX"}>Upgrade my plan now</a>
                                    </div>
                                </div>
                            );
                        }

                        if (auth.plan === 'business') {
                            return (
                                <div>
                                    <div>Business</div>
                                    <div>Oct 14 - Oct 15</div>
                                </div>
                            );
                        }

                        return (
                            <div>
                                <div>{auth.plan}</div>
                            </div>
                        );
                    })()
                }

                {
                    false
                    &&
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label className={"form-label"}>Password</label>
                            <input required={true} type={"password"} className={"form-control"} name={"password"} />
                        </div>

                        <button type={"submit"} className={"btn btn-primary"}>Update</button>
                    </form>
                }

                {
                    false &&
                    <div>
                        <button>Plan</button>
                        <button>Billing</button>
                    </div>
                }
            </div>
        </>
    );
}
