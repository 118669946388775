import {Outlet, NavLink, useNavigate, NavLinkProps} from "react-router-dom";
import {useEffect} from "react";
import {signOut} from "firebase/auth";

import {auth} from "../../services/firebase";

export default function Root() {
    const navigate = useNavigate();

    const handleEffect = async () => {
        // const response = await fetch('/functions/users/me');
        // const json = await response.json();
        // console.log(json);
    };

    useEffect(() => {
        handleEffect().then();
    }, []);

    const handleSignOut = async () => {
        await signOut(auth);
        navigate('/');
    };

    const handleNavLink: NavLinkProps['className'] = ({ isActive, isPending }) => {
        if (isActive) {
            return 'p-2 text-reset text-decoration-none nav-active';
        }

        return 'p-2 text-reset text-decoration-none';
    };

    return (
        <>
            <div style={{display: "grid", gridTemplateColumns: "200px 1fr"}}>
                <nav className={"d-flex flex-column"} style={{color: '#111111', background: '#f8f8f8', height: '100vh', position: 'sticky', top: 0}}>
                    <NavLink to={"/dashboard/candidates"} className={handleNavLink}>
                        <i className="bi bi-people"></i> Candidates
                    </NavLink>
                    <NavLink to={"/dashboard/mailboxes"} className={handleNavLink}>
                        <i className="bi bi-envelope"></i> Mailboxes
                    </NavLink>
                    <NavLink to={"/dashboard/profile/me"} className={handleNavLink}>
                        <i className="bi bi-file-person"></i> Profile
                    </NavLink>
                    <button className={"p-2 w-100 btn text-left text-reset btn"} type={"button"} onClick={handleSignOut}><i className="bi bi-box-arrow-right"></i> Sign out</button>
                </nav>

                <div>
                    <Outlet />
                </div>
            </div>
        </>
    );
}
