import {createContext, useEffect, useState, ReactNode} from "react";
import {onAuthStateChanged, User} from "firebase/auth";
import {auth} from "../services/firebase";

export const AuthContext = createContext<User | null>(null);

export const AuthProvider = ({ children }: {children: ReactNode;}) => {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });
    }, []);

    return (
        <AuthContext.Provider value={user}>{children}</AuthContext.Provider>
    );
};
