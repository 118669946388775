import React, {Fragment, forwardRef, useImperativeHandle, useRef, useState, useEffect, useContext} from 'react';
import { IFloatingFilterParams, NumberFilterModel } from 'ag-grid-community';
import {AuthContext} from "../contexts/authContext";

export interface CustomParams extends IFloatingFilterParams {
    industries: any[];
    suppressFilterButton: boolean;
}

export default forwardRef((props: CustomParams, ref) => {
    const [currentValue, setCurrentValue] = useState<number | null>(null);
    const selectRef = useRef<HTMLSelectElement>(null);
    const user = useContext(AuthContext);

    const handleEffect = async () => {
        if (!user) {
            return;
        }
    };

    useEffect(() => {
        handleEffect().then();
    }, [user]);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {
            onParentModelChanged(parentModel: NumberFilterModel) {
                // When the filter is empty we will receive a null value here
                if (!parentModel) {
                    selectRef.current!.value = '';
                    setCurrentValue(null);
                } else {
                    selectRef.current!.value = parentModel.filter + '';
                    setCurrentValue(parentModel.filter!);
                }
            },
        };
    });

    const onSelectChanged = (input: any) => {
        if (input.target.value === '') {
            // Remove the filter
            props.parentFilterInstance((instance) => {
                instance.onFloatingFilterChanged(null, null);
            });
            return;
        }

        setCurrentValue(Number(input.target.value));
        props.parentFilterInstance((instance) => {
            instance.onFloatingFilterChanged('equals', input.target.value);
        });
    };

    return (
        <Fragment>
            <select ref={selectRef} style={{width: '100%'}} onInput={onSelectChanged}>
                <option value={""}></option>
                <option value={"pending"}>Pending</option>
                <option value={"shortlisted"}>Shortlisted</option>
                <option value={"rejected"}>Rejected</option>
            </select>
        </Fragment>
    );
});
