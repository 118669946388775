import React, {FormEventHandler, MouseEventHandler, useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../contexts/authContext";
import {useNavigate, useParams} from "react-router-dom";

export default function Edit() {
    const user = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const [jobFunctions, setJobFunctions] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [attachments, setAttachments] = useState<{ response: Response, json: any } | null>(null);
    const [errorMessage, setErrorMessage] = useState('');

    const setInputValue = (name: string, data: string | null) => {
        const element = document.querySelector(`input[name="${name}"]`);
        if (element === null) {
            return;
        }
        if (data === null) {
            return;
        }

        element.setAttribute('value', data);
    };

    const setSelectValue = (name: string, data: string | null) => {
        const element: HTMLSelectElement | null = document.querySelector(`select[name="${name}"]`);

        if (element === null) {
            return;
        }
        if (data === null) {
            return;
        }

        element.value = data;
    };

    const handleEffect = async () => {
        if (!user) {
            return;
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/job-functions', {
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Accept": 'application/json',
                },
            });
            const json = await response.json();
            setJobFunctions(json?.data);
        } catch (e) {
            console.log(e)
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/industries', {
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Accept": 'application/json',
                },
            });
            const json = await response.json();
            setIndustries(json?.data);
        } catch (e) {
            console.log(e)
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/api/candidates/${id}`, {
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                },
            });
            const json = await response.json();

            if (!response.ok) {
                setErrorMessage(json.message ?? 'Request failed.');
                return;
            }

            setInputValue("name", json.data.name);
            setInputValue("position", json.data.position);
            setInputValue("email", json.data.email);
            setInputValue("phone", json.data.phone);
            setInputValue("expected_salary", json.data.expected_salary);
            setInputValue("years_of_experience", json.data.years_of_experience);
            setSelectValue("job_function_id", json.data.job_function_id);
            setSelectValue("industry_id", json.data.industry_id);
            setInputValue("remark", json.data.remark);
            setSelectValue("status", json.data.status);
        } catch (e) {
            console.log(e);
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/api/candidates/${id}/attachments`, {
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                },
            });
            const json = await response.json();

            setAttachments({
                response,
                json,
            });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        handleEffect().then();
    }, [id, user]);

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        const request: any = {};
        for (const [key, value] of formData.entries()) {
            request[key] = value;
        }

        try {
            await fetch(process.env.REACT_APP_API_URL + `/api/candidates/${id}`, {
                method: 'PUT',
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                },
                body: JSON.stringify(request),
            });

            navigate('/dashboard/candidates');
        } catch (e) {
            console.log(e);
        }
    };

    const handleDelete: MouseEventHandler<HTMLButtonElement> = async (event) => {
        event.preventDefault();

        if (!window.confirm("Do you really want to delete this candidate?")) {
            return;
        }

        try {
            await fetch(process.env.REACT_APP_API_URL + `/api/candidates:destroy`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                },
                body: JSON.stringify({
                    ids: [id],
                }),
            });

            navigate('/dashboard/candidates');
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <div className={"container p-2"}>
                <div className={'h1'}>Edit</div>

                {
                    errorMessage && <div className={"alert alert-danger"} role="alert">{ errorMessage }</div>
                }

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className={"form-label"}>Name</label>
                        <input className={"form-control"} type={"text"} name={"name"}/>
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Position</label>
                        <input className={"form-control"} type={"text"} name={"position"}/>
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Email</label>
                        <input className={"form-control"} type={"email"} name={"email"}/>
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Phone</label>
                        <input className={"form-control"} type={"text"} name={"phone"}/>
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Expected Annual Salary</label>
                        <input className={"form-control"} type={"number"} min={0} name={"expected_salary"}/>
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Years of Experience</label>
                        <input className={"form-control"} type={"number"} min={0} name={"years_of_experience"}/>
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Job Function</label>
                        <select className="form-select" name={"job_function_id"}>
                            <option value={""}></option>
                            {
                                jobFunctions.map((jobFunction: any) => (
                                    <option key={jobFunction?.id} value={jobFunction?.id}>{jobFunction?.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Industry</label>
                        <select className="form-select" name={"industry_id"}>
                            <option value={""}></option>
                            {
                                industries.map((industry: any) => (
                                    <option key={industry?.id} value={industry?.id}>{industry?.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Status</label>
                        <select className="form-select" name={"status"} required={true}>
                            <option value={""}></option>
                            <option value={"pending"}>Pending</option>
                            <option value={"shortlisted"}>Shortlisted</option>
                            <option value={"rejected"}>Rejected</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Remarks</label>
                        <textarea className={"form-control"} rows={3} name={"remark"}></textarea>
                    </div>

                    <div className={"d-flex gap-3"}>
                        <button type={'button'} className={"btn btn-danger"} onClick={handleDelete}>Delete</button>
                        <button type={'submit'} className={"btn btn-primary"}>Submit</button>
                    </div>
                </form>

                <hr />

                <div>
                    <div className={'h1'}>Attachments (All attachments are not saved during trial version)</div>

                    {
                        attachments !== null
                        &&
                        <>
                            {
                                attachments.json?.data?.length === 0
                                &&
                                <div>No attachments for this candidate.</div>
                            }

                            {
                                attachments.json?.data?.map((data: any) => (
                                    <div>{data}</div>
                                ))
                            }
                        </>
                    }
                </div>
            </div>
        </>
    );
}
