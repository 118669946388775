import {FormEventHandler, useContext, useEffect, useRef, MouseEventHandler, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AuthContext} from "../../../contexts/authContext";

export default function Edit() {
    const user = useContext(AuthContext);
    const { id } = useParams();
    const form = useRef<HTMLFormElement>(null);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const request: any = {};

        for (const [key, value] of formData.entries()) {
            request[key] = value;
        }

        const response = await fetch(process.env.REACT_APP_API_URL + `/api/mailboxes/${id}`, {
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${await user?.getIdToken()}`,
                "Accept": 'application/json',
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        });
        const json = await response.json();

        if (!response.ok) {
            setErrorMessage(json.message ?? 'Request failed.');
            return;
        }

        navigate('/dashboard/mailboxes');
    };

    const handleEffect = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/api/mailboxes/${id}`, {
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Accept": 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error("Cannot fetch mailboxes");
            }
            const json = await response.json();

            document.querySelector('input[name="host"]')?.setAttribute('value', json.data.host);
            document.querySelector('input[name="user"]')?.setAttribute('value', json.data.user);
            document.querySelector('input[name="port"]')?.setAttribute('value', json.data.port);
            document.querySelector('input[name="inbox"]')?.setAttribute('value', json.data.inbox);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        handleEffect().then();
    }, [id]);

    return (
        <>
            <div className={"container p-2"}>
                <div className={'h1'}>Edit</div>

                {
                    errorMessage && <div className={"alert alert-danger"} role="alert">{ errorMessage }</div>
                }

                <form onSubmit={handleSubmit} ref={form}>
                    <div className="mb-3">
                        <label className={"form-label"}>Host</label>
                        <input className={"form-control"} type={"text"} required={true} name={"host"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>User</label>
                        <input className={"form-control"} type={"text"} required={true} name={"user"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Password</label>
                        <input className={"form-control"} type={"password"} required={true} name={"password"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Port</label>
                        <input className={"form-control"} type={"number"} required={true} name={"port"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Inbox</label>
                        <input className={"form-control"} type={"text"} required={true} name={"inbox"} />
                    </div>

                    <button type={'submit'} className={"btn btn-primary"}>Submit</button>
                </form>
            </div>
        </>
    );
}
