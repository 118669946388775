import {FormEventHandler, useState} from "react";
import {signInWithEmailAndPassword} from "firebase/auth";
import {useNavigate} from "react-router-dom";

import {auth} from "../services/firebase";

export default function LogIn() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        try {
            await signInWithEmailAndPassword(auth, data.get('email') as string, data.get('password') as string);
            navigate('/dashboard/candidates');
        } catch (error: any) {
            if (error.code === "auth/wrong-password") {
                setErrorMessage("Invalid password.");
                return;
            } else if (error.code === "auth/user-not-found") {
                setErrorMessage("User not found.");
                return;
            }

            setErrorMessage(error.message);
        }
    };

    return (
        <>
            <div className={"container"} style={{marginTop: 140, maxWidth: 400}}>
                <div className={"h1 text-center"}>Log In</div>

                {
                    errorMessage && <div className={"alert alert-danger"} role="alert">{ errorMessage }</div>
                }

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className={"form-label"}>Email</label>
                        <input className={"form-control"} type={"email"} required={true} name={"email"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Password</label>
                        <input className={"form-control"} type={"password"} required={true} name={"password"} />
                    </div>

                    <button type={'submit'} className={'btn btn-primary w-100'}>Log In</button>
                </form>
            </div>
        </>
    );
}
