import {FormEventHandler, useContext} from "react";
import {AuthContext} from "../../../contexts/authContext";
import {useNavigate} from "react-router-dom";

export default function Create() {
    const user = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const request: any = {};

        for (const [key, value] of formData.entries()) {
            request[key] = value;
        }

        await fetch(process.env.REACT_APP_API_URL + '/api/mailboxes', {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${await user?.getIdToken()}`,
                "Accept": 'application/json',
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        });

        navigate('/dashboard/mailboxes');
    };

    return (
        <>
            <div className={"container p-2"}>
                <div className="h1">Create</div>

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className={"form-label"}>Host</label>
                        <input className={"form-control"} type={"text"} required={true} name={"host"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>User</label>
                        <input className={"form-control"} type={"text"} required={true} name={"user"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Password</label>
                        <input className={"form-control"} type={"password"} required={true} name={"password"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Port</label>
                        <input className={"form-control"} type={"number"} required={true} name={"port"} defaultValue={993} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Inbox</label>
                        <input className={"form-control"} type={"text"} required={true} name={"inbox"} />
                    </div>

                    <button className={"btn btn-primary"} type={'submit'}>Submit</button>
                </form>
            </div>
        </>
    );
}
