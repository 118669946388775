import {FormEventHandler, useState} from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";

import {auth} from "../services/firebase";
import {useNavigate} from "react-router-dom";

export default function SignUp() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        try {
            if (data.get('password') !== data.get('confirm_password')) {
                throw "Password should be identical.";
            }

            await createUserWithEmailAndPassword(auth, data.get('email') as string, data.get('password') as string);

            navigate('/login');
        } catch (error: any) {
            if (error.code === "auth/wrong-password") {
                setErrorMessage("Invalid password.");
                return;
            } else if (error.code === "auth/user-not-found") {
                setErrorMessage("User not found.");
                return;
            }

            setErrorMessage(error.message);
        }
    };

    return (
        <>
            <div className={"container"} style={{marginTop: 140, maxWidth: 400}}>
                <div className={"h1 text-center"}>Sign Up</div>

                {
                    errorMessage && <div className={"alert alert-danger"} role="alert">{ errorMessage }</div>
                }

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className={"form-label"}>Email</label>
                        <input className={"form-control"} type={"email"} required={true} name={"email"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Password</label>
                        <input className={"form-control"} type={"password"} required={true} name={"password"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Confirm Password</label>
                        <input className={"form-control"} type={"password"} required={true} name={"confirm_password"} />
                    </div>

                    <button type={'submit'} className={'btn btn-primary w-100'}>Sign Up</button>
                </form>
            </div>
        </>
    );
}
