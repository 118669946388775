import React, {FormEventHandler, MouseEventHandler, useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../contexts/authContext";
import {useNavigate} from "react-router-dom";

export default function Create() {
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const user = useContext(AuthContext);
    const [jobFunctions, setJobFunctions] = useState([]);
    const [industries, setIndustries] = useState([]);

    const handleEffect = async () => {
        if (!user) {
            return;
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/job-functions', {
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Accept": 'application/json',
                },
            });
            const json = await response.json();
            setJobFunctions(json?.data);
        } catch (e) {
            console.log(e)
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/industries', {
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Accept": 'application/json',
                },
            });
            const json = await response.json();
            setIndustries(json?.data);
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        handleEffect().then();
    }, [user]);

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        const request: any = {};
        for (const [key, value] of formData.entries()) {
            request[key] = value ? value : null;
        }

        try {
            await fetch(process.env.REACT_APP_API_URL + `/api/candidates/store`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${await user?.getIdToken()}`,
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                },
                body: JSON.stringify(request),
            });

            navigate('/dashboard/candidates');
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <div className={"container p-2"}>
                <div className={'h1'}>Create</div>

                {
                    errorMessage && <div className={"alert alert-danger"} role="alert">{ errorMessage }</div>
                }

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className={"form-label"}>Name</label>
                        <input className={"form-control"} type={"text"} name={"name"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Position</label>
                        <input className={"form-control"} type={"text"} name={"position"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Email</label>
                        <input className={"form-control"} type={"email"} name={"email"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Phone</label>
                        <input className={"form-control"} type={"text"} name={"phone"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Expected Annual Salary</label>
                        <input className={"form-control"} type={"number"} min={0} name={"expected_salary"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Years of Experience</label>
                        <input className={"form-control"} type={"number"} min={0} name={"years_of_experience"} />
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Job Function</label>
                        <select className="form-select" name={"job_function_id"}>
                            <option value={""}></option>
                            {
                                jobFunctions.map((jobFunction: any) => (
                                    <option key={jobFunction?.id} value={jobFunction?.id}>{jobFunction?.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Industry</label>
                        <select className="form-select" name={"industry_id"}>
                            <option value={""}></option>
                            {
                                industries.map((industry: any) => (
                                    <option key={industry?.id} value={industry?.id}>{industry?.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className={"form-label"}>Remarks</label>
                        <textarea className={"form-control"} rows={3} name={"remark"}></textarea>
                    </div>

                    <div className={"d-flex gap-3"}>
                        <button type={'submit'} className={"btn btn-primary"}>Submit</button>
                    </div>
                </form>
            </div>
        </>
    );
}