import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './index.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from './contexts/authContext';

import Index from './routes/index';
import LogIn from './routes/login';
import SignUp from './routes/signup';
import Root from './routes/dashboard/root';
import MailboxesIndex from './routes/dashboard/mailboxes/index';
import MailboxesCreate from './routes/dashboard/mailboxes/create';
import MailboxesEdit from './routes/dashboard/mailboxes/edit';
import CandidatesIndex from './routes/dashboard/candidates/index';
import CandidatesCreate from './routes/dashboard/candidates/create';
import CandidatesEdit from './routes/dashboard/candidates/edit';
import ProfileMe from './routes/dashboard/profile/me';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Index />,
    },
    {
        path: "/login",
        element: <LogIn />,
    },
    {
        path: "/signup",
        element: <SignUp />,
    },
    {
        element: <Root />,
        children: [
            {
                path: "/dashboard/mailboxes",
                element: <MailboxesIndex />,
            },
            {
                path: "/dashboard/mailboxes/create",
                element: <MailboxesCreate />,
            },
            {
                path: "/dashboard/mailboxes/:id/edit",
                element: <MailboxesEdit />,
            },
            {
                path: "/dashboard/candidates",
                element: <CandidatesIndex />,
            },
            {
                path: "/dashboard/candidates/create",
                element: <CandidatesCreate />,
            },
            {
                path: "/dashboard/candidates/:id/edit",
                element: <CandidatesEdit />,
            },
            {
                path: "/dashboard/profile/me",
                element: <ProfileMe />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <AuthProvider>
          <RouterProvider router={router} />
      </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
