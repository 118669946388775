import {Link} from "react-router-dom";
import {useContext} from "react";
import {AuthContext} from "../contexts/authContext";

export default function Index() {
    const user = useContext(AuthContext);

    return (
        <>
            <div style={{backgroundColor: '#13142d', paddingTop: 190, paddingBottom: 190}}>
                <div className={"container d-flex justify-content-center align-items-center flex-column"} style={{position: 'relative'}}>
                    <div style={{width: 300}}>
                        <img src={process.env.PUBLIC_URL + '/logo.jpg'} style={{maxWidth: '100%', height: 'auto', display: 'block', padding: 20, background: 'white', borderRadius: '20%', marginBottom: 20}} />
                    </div>
                    <div className={'text-white h1 mb-3'}>Mayoo</div>
                    <div className={'text-white h3 mb-3'}>CONVERT applicants' emails from Job Board(*) to Database</div>
                    <div style={{width: 300}}>
                        {
                            user
                                ? <div>
                                    <Link to={"/dashboard/candidates"} className={"w-100 btn btn-primary"}>Dashboard</Link>
                                </div>
                                : <div>
                                    <div className={"mb-3"}><Link to={"/signup"} className={"w-100 btn btn-primary"}>Register</Link></div>
                                    <div><Link to={"/login"} className={"w-100 btn btn-outline-primary"}>Login</Link></div>
                                </div>
                        }
                    </div>
                </div>
            </div>

            <div className={"container py-5"}>
                <div className={"h1"}>SIGN UP for FREE with basic feature and change your entire hiring work!</div>
                <div>
                    <ul>
                        <li>NO MORE DATA ENTRY</li>
                        <li>KEEPS YOUR CANDIDATES FOREVER</li>
                        <li>SEND THEM WhatsApp or WeChat</li>
                    </ul>
                </div>
            </div>

            <div style={{backgroundColor: '#fafafa'}}>
                <div className={"container py-5"}>
                    <div className={"h1"}>NO DATA ENTRY</div>
                    <div>MAYOO analyzes your email from job-boards (*) and automatically store them in your own database.</div>
                </div>

                <div className={"container py-5"}>
                    <div className={"h1"}>KEEP YOUR TALENT FOREVER</div>
                    <div>Owning your own Talent Pool without expiration. As you subscribe to MAYO with a very reasonable fee, all candidates profile will be stored in your own database.</div>
                </div>

                <div className={"container py-5"}>
                    <div className={"h1"}>Management, Campaign and Group Action</div>
                    <div>
                        <ul>
                            <li>MAYOO enables user to execute group action (delete, write notes, edit, etc.)</li>
                            <li>Provide Field Search, Sorting, Filtering</li>
                            <li>Enable group administration plus email campaign, WhatsApp campaign, WeChat campaign.</li>
                            <li>Enable data export</li>
                        </ul>
                    </div>
                </div>

                <div className={"container py-5"}>
                    <div className={"h1"}>
                        Pricing
                    </div>

                    <div>
                        <div>99.00 USD / month</div>
                        <div>899.00 USD / year</div>
                    </div>
                </div>

                <div className={"container py-5"}>
                    <div>(*) Now, Mayoo can only auto transfer JobsDB.com.hk applications. Coming soon:</div>
                    <div>CTgoodjobs, Recruit.com.hk, eFinanceCareer, Indeed.</div>
                </div>
            </div>
        </>
    );
}
